import dayjs from 'dayjs';

import getISODate from 'lib/getISODate';
import { getPublisher } from 'lib/headerMetadata';
import { getCloudinaryFlavoredImage } from 'lib/imageUtils';
import { verticalSiteConfigs } from 'lib/verticalSiteConfigs';
import { removeRichTextMarkupFromItems } from 'lib/richMarkupUtils';
import { buildBlogPosting } from './buildBlogPosting';
import { buildAuthors } from './buildAuthors';
import { cloudinaryFlavorLd } from './cloudinaryFlavorLd';

/**
 * getLiveEventRegEx
 * @param {VerticalType} vertical
 */
export function getLiveEventRegEx(vertical) {
  return new RegExp(`(?:${vertical}|nbcnews)/usageRule/commerce-live-event-(\\d+)-hrs`);
}

/**
 * LiveBlogPosting
 * @param {Article} content
 * @param {VerticalType} vertical
 */
export function buildLiveBlog(content, vertical) {
  const {
    authors,
    dateCreated,
    datePublished,
    description,
    headline,
    items,
    url,
    primaryMedia,
    taxonomy: {
      allTerms,
    },
    content: {
      text,
    },
    body,
  } = content;

  const liveBlogImage = primaryMedia?.image?.url?.primary ?? null;
  const liveEventRegEx = new RegExp(`(?:${vertical}|nbcnews)/usageRule/commerce-live-event-(\\d+)-hrs`);
  const term = (allTerms || []).find(({ path }) => path.match(liveEventRegEx));
  const isLiveEvent = Boolean(term);
  const coverageHours = Number(term?.path?.match(liveEventRegEx)?.[1] || '22');
  const coverageStartTime = dayjs(dateCreated).toISOString();
  const coverageEndTime = dayjs(coverageStartTime).add(coverageHours, 'hour').toISOString();


  const addSummaryBoxToArticleBody = (summaryBox) => {
    const { headline: summaryBoxHeadline, items: summaryBoxItems } = summaryBox?.widget?.properties?.embed || {};
    const removedRichTextMarkup = removeRichTextMarkupFromItems(summaryBoxItems);
    const summaryContent = ` ${summaryBoxHeadline} ${removedRichTextMarkup?.join(' ')}`;
    return summaryContent;
  };

  const addArticleBody = (schema) => {
    const updatedSchema = {
      ...schema,
    };
    const summaryBox = body?.find((item) => item?.widget?.properties?.embed?.type === 'SUMMARY_BOX');

    if (text && Array.isArray(text)) {
      updatedSchema.articleBody = text.join('\n\n');
    } else if (description && (description.seo || description.primary)) {
      updatedSchema.articleBody = description.seo || description.primary;
    }

    if (body && summaryBox) {
      updatedSchema.articleBody += addSummaryBoxToArticleBody(summaryBox);
    }

    return updatedSchema;
  };

  const getUpdates = () => {
    if (isLiveEvent) {
      const liveBlogPost = {
        '@context': 'https://schema.org/',
        '@type': 'BlogPosting',
        publisher: getPublisher(vertical),
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': url.primary,
        },
        headline: headline.seo || headline.primary,
        url: url.primary,
        datePublished: coverageStartTime,
        dateModified: getISODate(datePublished),
        author: buildAuthors({ authors, vertical }),
      };

      return [addArticleBody(liveBlogPost)];
    }

    return Array.isArray(items)
      && items.map((blog) => buildBlogPosting(blog, vertical, content));
  };

  const liveBlog = {
    '@context': {
      '@vocab': 'https://schema.org',
    },
    '@type': 'LiveBlogPosting',
    author: {
      '@type': 'NewsMediaOrganization',
      name: getPublisher(vertical).name,
      // Default value for testing purposes
      url: vertical === 'select' ? 'https://www.nbcnews.com' : verticalSiteConfigs[vertical]?.siteUrl || 'https://www.nbcnews.com',
    },
    url: url.primary,
    coverageStartTime,
    datePublished: coverageStartTime,
    // datePublished maps to the published date of the last card added in the blog
    dateModified: getISODate(datePublished),
    headline: headline.seo || headline.primary,
    description: description.seo || description.primary,
    liveBlogUpdate: getUpdates(),
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url.primary,
    },
    coverageEndTime,
    publisher: getPublisher(vertical),
  };

  if (liveBlogImage) {
    liveBlog.image = getCloudinaryFlavoredImage(liveBlogImage, cloudinaryFlavorLd);
  }

  // Do not add Article Body at top Level for live events
  if (isLiveEvent) {
    return liveBlog;
  }

  return addArticleBody(liveBlog);
}
