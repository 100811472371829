import { getSchemaTemplate } from './getSchemaTemplate';

/**
 * Organization
 * @param {string} organization
 */
export const buildOrganization = (organization) => {
  // Organization schema
  const schema = {
    ...getSchemaTemplate('Organization'),
    name: organization,
  };
  return schema;
};
