/* eslint-disable max-len */
/**
 * @param {VerticalType} vertical
 */
import { buildBrandSocials } from './social';

export function getPublisher(vertical) {
  switch (vertical) {
    case 'msnbc':
      return {
        '@type': 'NewsMediaOrganization',
        name: 'MSNBC',
        logo: {
          '@type': 'ImageObject',
          url: 'https://media-cldnry.s-nbcnews.com/image/upload/newsgroup-logos/msnbc/logo-horizontal/default-black-424x60.png',
        },
        sameAs: buildBrandSocials(vertical),
      };

    case 'noticias':
      return {
        '@type': 'NewsMediaOrganization',
        name: 'TELEMUNDO',
        logo: {
          '@type': 'ImageObject',
          url: 'https://media-cldnry.s-nbcnews.com/image/upload/h_60/v1696280688/newsgroup-logos/nbcnews/logo/primary-black-424x45.png',
        },
        sameAs: buildBrandSocials(vertical),
      };

    case 'today':
      return {
        '@type': 'NewsMediaOrganization',
        name: 'TODAY',
        logo: {
          '@type': 'ImageObject',
          url: 'https://media-cldnry.s-nbcnews.com/image/upload/v1657223867/rockcms/2022-07/TODAY-Publisher-Logo-8226ca.svg',
        },
        sameAs: buildBrandSocials(vertical),
      };

    case 'select':
      return {
        '@type': 'NewsMediaOrganization',
        name: 'NBC Select',
        logo: {
          '@type': 'ImageObject',
          url: 'https://media-cldnry.s-nbcnews.com/image/upload/h_60/newsgroup-logos/nbc-select/logo-horizontal/default-blue-360x102.png',
        },
        sameAs: buildBrandSocials(vertical),
      };

    default:
      return {
        '@type': 'NewsMediaOrganization',
        name: 'NBC News',
        logo: {
          '@type': 'ImageObject',
          url: 'https://media-cldnry.s-nbcnews.com/image/upload/h_60/v1696280688/newsgroup-logos/nbcnews/logo/primary-black-424x45.png',
        },
        sameAs: buildBrandSocials('news'),
      };
  }
}

// TODO: [BENTO-21615] Should getCreator be updated to use src/lib/verticalSiteConfigs.js instead?
const CREATORS = {
  today: 'TODAY.com',
  msnbc: 'MSNBC.com',
};

/**
 * @param {VerticalType} vertical
 */
export function getCreator(vertical) {
  return CREATORS[vertical] || 'NBCNews.com';
}
