import { parsePrice } from 'lib/productOfferPriceHelpers';
import { OFFER_OUT_OF_STOCK as OUT_OF_STOCK } from 'lib/constants';

import {
  buildProsCons,
} from './buildProsCons';

const calcPriceOffers = (offers) => {
  const prices = [];

  offers.forEach((offer) => {
    if (offer?.prices?.length) {
      offer.prices.forEach((price) => {
        prices.push(parsePrice(price.price) ?? 0);
      });
    }
  });
  return prices;
};

const imageExists = (imageUrl) => (imageUrl && imageUrl !== '#');
const getAvailability = (offerState) => (offerState === OUT_OF_STOCK ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock');

/**
 * ListicleProduct Component
 *
 * @param {Object} obj
 * @param {string} obj.name
 * @param {string} obj.description
 * @param {string} obj.productUrl
 * @param {number} obj.offers
 * @param {string} obj.imageUrl
 */
export const buildListicleProduct = ({
  name,
  description,
  productUrl,
  offers,
  imageUrl,
  prosAndCons,
  article = {},
}) => {
  const prices = calcPriceOffers(offers).sort((a, b) => a - b);

  const productJSONLD = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    brand: {
      '@type': 'Thing',
    },
  };


  if (imageExists(imageUrl)) { productJSONLD.image = [imageUrl]; }

  if (description?.primary === null) {
    productJSONLD.description = name;
  }

  const allOffers = [];
  offers.forEach((offer) => {
    const availability = offer?.state === 'OUT_OF_STOCK'
      ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock';
    allOffers.push({
      '@type': 'Offer',
      url: offer.externalUrl,
      availability,
      offeredBy: {
        name: offer?.seller?.name,
        '@type': 'Organization',
      },
    });
  });

  let lowest;
  let highest;
  let aggregateOffer;
  let singleOffer;

  if (offers?.length > 1) {
    highest = prices.pop();
    lowest = prices.shift();
    aggregateOffer = {
      '@type': 'AggregateOffer',
      highPrice: highest,
      lowPrice: lowest,
      offerCount: offers.length,
      offers: allOffers,
    };

    if (offers[0].prices) {
      aggregateOffer.priceCurrency = offers[0].prices[0].priceCurrency;
    }
  } else if (offers?.length === 1) {
    const availability = getAvailability(offers[0]?.state);
    singleOffer = {
      '@type': 'Offer',
      url: productUrl,
      availability,
    };

    if (offers[0].prices) {
      singleOffer.priceCurrency = offers[0].prices[0].priceCurrency;
      singleOffer.price = parsePrice(offers[0].prices[0].price);
      singleOffer.offeredBy = {
        name: offers[0]?.seller?.name ?? '',
        '@type': 'Organization',
      };
    }
  } else {
    singleOffer = {
      '@type': 'Offer',
      url: productUrl,
      priceCurrency: '',
      price: 0,
      offeredBy: {},
    };
  }

  productJSONLD.offers = aggregateOffer ?? singleOffer;

  if (prosAndCons?.pros?.items?.length > 0) productJSONLD.review = buildProsCons(prosAndCons, article);

  return JSON.stringify(productJSONLD);
};
